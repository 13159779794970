<template>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">24小时最新事件</span>
						<div class="text-900 font-medium text-xl">{{ dayEventCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round"
						style="width:2.5rem;height:2.5rem">
						<i class="pi pi-sun text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">本周事件统计</span>
						<div class="text-900 font-medium text-xl">{{ weekEventCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round"
						style="width:2.5rem;height:2.5rem">
						<i class="pi pi-calendar text-orange-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">本月云分析事件总数</span>
						<div class="text-900 font-medium text-xl">{{ thisMonthReviewedCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
						style="width:2.5rem;height:2.5rem">
						<i class="pi pi-inbox text-cyan-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">本月违规事件总数</span>
						<div class="text-900 font-medium text-xl">{{ thisMonthNotReviewedCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round"
						style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 xl:col-6">
			<div class="card">
				<div class="flex justify-content-between align-items-center">
					<h5>最新事件（Top 10）</h5>
					<div>
						<Button label="更多" class="p-button-text p-button-info mr-2 mb-2" @click="gotoEventRecords" />
					</div>
				</div>
				<DataTable :value="eventRecordList" :rows="10" :paginator="true" responsiveLayout="scroll">
					<Column style="width:15%">
						<template #header>
							首警图
						</template>
						<template #body="slotProps">
							<img :src="slotProps.data.remind_img" :alt="slotProps.data.position" width="50"
								class="shadow-2" />
						</template>
					</Column>
					<Column field="name" header="类型" :sortable="true" style="width:35%">
						<template #body="slotProps">
							{{ slotProps.data.name }}
						</template>
					</Column>
					<Column field="time" header="时间" :sortable="true" style="width:35%">
						<template #body="slotProps">
							{{ slotProps.data.times }}
						</template>
					</Column>
					<Column style="width:15%">
						<template #header>
							详细
						</template>
						<template #body="slotProps">
							<Button icon="pi pi-search" type="button" class="p-button-text"
								@click="getDetails(slotProps.data)"></Button>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="col-12 xl:col-6">
			<div class="card">
				<div class="flex justify-content-between align-items-center">
					<h5>事件类型统计</h5>
					<div>
						<Button label="昨日"
							:class="[chartIndex == 0 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(0)" />
						<Button label="今日"
							:class="[chartIndex == 1 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(1)" />
						<Button label="本周"
							:class="[chartIndex == 2 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(2)" />
						<Button label="本月"
							:class="[chartIndex == 3 ? 'p-button-rounded p-button-info mr-2 mb-2' : 'p-button-text p-button-secondary mr-2 mb-2']"
							@click="onChartStatChange(3)" />
					</div>
				</div>
				<div class="flex flex-column align-items-center">
					<Chart type="pie" :data="chartStatisticData" :options="pieOptions" style="width: 70%" />
				</div>
			</div>
			<div class="card" v-if="localUserInfo && localUserInfo.admin && localUserInfo.admin != 0">
				<div class="flex justify-content-between align-items-center mb-5">
					<h5>本月未审核统计（Top 10）</h5>
					<div>
						<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded"
							@click="$refs.menu2.toggle($event)"></Button>
						<Menu ref="menu2" :popup="true" :model="notReviewedMenu"></Menu>
					</div>
				</div>
				<!-- 绿色到酱红色渐变，5种阶梯就行，绿、黄绿、黄、红、酱 -->
				<ul class="list-none p-0 m-0">
					<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
						v-for="item in topNList" :key="item.index">
						<div>
							<span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.title }}</span>
							<div class="mt-1 text-600">{{ item.subtitle }}</div>
						</div>
						<div class="mt-2 md:mt-0 flex align-items-center">
							<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
								<div :class="['bg-' + item.textColor, 'h-full']"
									:style="{ 'width': item.notReviewedPercent + '%' }">
								</div>
							</div>
							<span :class="['text-' + item.textColor, 'ml-3', 'font-medium']">{{ item.notReviewedPercent
							}}%</span>
						</div>
					</li>
				</ul>
			</div>
			<div class="card" v-if="false">
				<div class="flex align-items-center justify-content-between mb-4">
					<h5>通知</h5>
					<div>
						<Button label="更多" class="p-button-text p-button-info mr-2 mb-2" @click="gotoNotifyList" />
					</div>
				</div>

				<div v-for="(item, index) in notifyList" :key="index">
					<span class="block text-600 font-medium mb-3">{{ item.title }}</span>
					<ul class="p-0 mx-0 mt-0 mb-4 list-none">
						<li class="flex align-items-center py-2 border-bottom-1 surface-border"
							v-for="(child, cindex) in item.list" :key="cindex">
							<div v-if="child.type == 1"
								class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
								<i class="pi pi-cloud text-xl text-blue-500"></i>
							</div>
							<div v-else
								class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
								<i class="pi pi-send text-xl text-green-500"></i>
							</div>

							<span class="text-900 line-height-3">{{ child.type == 1 ? "系统通知" : "企业通知" }}
								<div class="text-700 text-overflow-ellipsis white-space-nowrap overflow-hidden"
									style="width:400px">
									{{ child.text }}
								</div>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<!-- 详情页 -->
	<Dialog header="事件详情" v-model:visible="displayDetail" :style="{ width: '1050px' }" :modal="true"
		@hide="onDetailHide">
		<template #header>
			<div class="flex align-items-center">
				<div class="p-dialog-title">事件详情</div>
			</div>
		</template>

		<ScrollPanel style="width: 100%; height: 100%">
			<div class="formgrid grid" style="width:1000px">
				<div class="field col">
					<label for="firstname2">
						<Tag class="mr-2" value="首警图" @click="onRuleClick(0)"></Tag>
						<Tag class="mr-2" severity="warning" value="规则" @click="onRuleClick(1)" v-if="false"></Tag>
					</label>
					<div class="flex justify-content-left">
						<img :src="eventDetail.remind_img" :alt="eventDetail.name" width="480" height="270"
							v-if="!showRule" />
						<Card style="width: 100%; height:270px" v-if="showRule">
							<template #title>
								{{ eventDetail.name }}
							</template>
							<template #content>
								<p>测试功能描述</p>
							</template>
						</Card>
					</div>
				</div>
				<div class="field col">
					<label for="lastname2">
						<Tag class="mr-2" severity="success" value="视频"></Tag>
					</label>
					<div class="flex justify-content-left">
						<div style="width:480px; height: 270px; position: relative;">
							<easy-player id="videoplayer" live="true" show-custom-button="true"
								v-bind:video-url="eventDetail.remind_video" decode-wasm="true">
							</easy-player>
						</div>
					</div>
				</div>
			</div>

			<div class="formgrid grid" style="width:1000px">
				<div class="field col-12 md:col-6 flex-column ">
					<div class="col mb-3 mt-2 grid">
						<div class="col">
							<span class="product-badge status-instock mr-2">企业</span>
							<span class="product-badge-normal">
								{{ eventDetail.enterprise_name }}
							</span>
						</div>
					</div>
					<div class="col mb-3 mt-3 grid">

						<div class="col">
							<span class="product-badge status-instock mr-2">场地</span>
							<span class="product-badge-normal">{{ eventDetail.site_name }}</span>
						</div>
					</div>

					<div class="col mb-3 mt-3 grid">

						<div class="col">
							<span class="product-badge status-instock mr-2">区域</span>
							<span class="product-badge-normal">{{ eventDetail.region_name }}</span>
						</div>
					</div>

					<div class="col mb-3 mt-3 grid">

						<div class="col">
							<span class="product-badge status-instock mr-2">点位</span>
							<span class="product-badge-normal">{{ eventDetail.enterprise_camer_name
							}}</span>
						</div>
					</div>

					<div class="col mb-3 mt-3 grid">
						<div class="col">
							<span class="product-badge status-instock mr-2">摄像头IP</span>
							<span class="product-badge-normal">{{ eventDetail.mac }}</span>
						</div>
					</div>

				</div>

				<div class="field col-12 md:col-6 flex-column ">
					<div class="col mt-2 mb-3">
						<span class="product-badge status-instock mr-2">违规事件</span>
						<span class="product-badge-normal">{{ eventDetail.name }}</span>
					</div>

					<div class="col mt-2 mb-3">
						<span class="product-badge status-instock mr-2">风险等级</span>
						<span class="product-badge-normal">{{ eventDetail.level }} 级</span>
					</div>

					<div class="col mb-3 mt-3 grid">
						<div class="col">
							<span class="product-badge status-instock mr-2">拍摄时间</span>
							<span class="product-badge-normal">{{ eventDetail.times }}</span>
						</div>
					</div>

					<div class="col mb-3 mt-3 grid">
						<div class="col">
							<span class="product-badge status-instock mr-2">审核状态</span>
							<Tag severity="success" v-if="eventDetail.status == 1" value="合规"></Tag>
							<Tag severity="danger" v-else-if="eventDetail.status == 2" value="违规">
							</Tag>
							<Tag severity="warning" v-else value="未审阅"></Tag>
						</div>
					</div>
				</div>

				<div class="formgrid grid m-3 mt-0" style="width:1000px">
					<Panel header="审核记录" :toggleable="true">
						<div class="flex flex-column" style="width:930px;">
							<div class="flex py-2" style="border-bottom:1px #f2f2f2 solid"
								v-for="(item, index) in eventDetail.records" @click="onDetailRecordClick(index)"
								:key="item.id">
								<div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white border-circle"
									style="width:50px; height:50px">
									<img :src="item.head_img != null && item.head_img != '' ? item.head_img : 'images/avatar/male_user.svg'"
										style="width:50px; height:50px; border-radius: 50%;" />
								</div>
								<div
									class="flex-grow-1 product-badge-normal lowercase flex align-items-center justify-content-center ml-2">
									{{ item.text }}
								</div>
								<div class="flex-none flex product-badge-normal align-items-center justify-content-center ml-2"
									style="width:150px">
									{{ item.cur_type == null ? '最开始' : item.cur_type }}
								</div>
								<div class="flex-none flex product-badge-normal capitalize align-items-center justify-content-center ml-2"
									style="width:70px">
									{{ item.username }}</div>
								<div class="flex-none flex product-badge-normal align-items-center justify-content-center ml-2"
									style="width:110px">
									{{ item.times }}</div>
							</div>
						</div>
					</Panel>
				</div>
			</div>
		</ScrollPanel>
	</Dialog>
</template>

<script>
import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';
import dateUtil from '../utils/dateUtil.js'

export default {
	data() {
		return {
			//------------------------------------------------
			screen: {
				enterprise: '',
				field: '',
				time: {
					state_time: '',
					end_time: ''
				},
			},
			dayEventCount: 0,
			weekEventCount: 0,
			thisMonthReviewedCount: 0,
			thisMonthNotReviewedCount: 0,

			localUserInfo: null,

			todayDailyStatData: null,			// Daily原始数据缓存
			yesterdayDailyStatData: null,
			thisWeekDailyStatData: null,
			thisMonthDailyStatData: null,

			chartIndex: -1,
			todayChartStatData: {
				labels: [],
				data: []
			},
			yesterdayChartStatData: {
				labels: [],
				data: []
			},
			thisWeekChartStatData: {
				labels: [],
				data: []
			},
			thisMonthChartStatData: {
				labels: [],
				data: []
			},
			chartStatisticData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#A52A2A",
							"#228B22",
							"#8A2BE2",
							"#FF8C00",
							"#DAA520",
							"#FF00FF",
							"#8B008B",
							"#00CED1",
							"#FF4500",
							"#7FFF00",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#A52A2A",
							"#228B22",
							"#8A2BE2",
							"#FF8C00",
							"#DAA520",
							"#FF00FF",
							"#8B008B",
							"#00CED1",
							"#FF4500",
							"#7FFF00"
						],
						hoverBackgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#A52A2A",
							"#228B22",
							"#8A2BE2",
							"#FF8C00",
							"#DAA520",
							"#FF00FF",
							"#8B008B",
							"#00CED1",
							"#FF4500",
							"#7FFF00",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#A52A2A",
							"#228B22",
							"#8A2BE2",
							"#FF8C00",
							"#DAA520",
							"#FF00FF",
							"#8B008B",
							"#00CED1",
							"#FF4500",
							"#7FFF00"
						]
					}
				]
			},

			enterpriseTopN: [],
			fieldTopN: [],
			regionTopN: [],

			topNList: [],
			eventRecordList: null,
			eventDetail: {},
			displayDetail: false,
			noImgPic: "images/pages/No_image_available.svg",
			//------------------------------------------------

			products: null,
			notifyTimeRange: [
				{ label: '今天' },
				{ label: '两天' },
				{ label: '一周' }
			],
			notifyList: [],
			notReviewedMenu: [
				{
					label: '区域',
					command: () => {
						this.topNList = this.regionTopN
					}
				},
				{
					label: '场地',
					command: () => {
						this.topNList = this.fieldTopN
					}
				},
				{
					label: '企业',
					command: () => {
						this.topNList = this.enterpriseTopN
					}
				}
			],
			lineOptions: null,
			pieOptions: {
				plugins: {
					legend: {
						labels: {
							color: '#495057',
							boxWidth: 40
						},
						position: 'right',
					}
				}
			},
			enterpriseId: '',
		}
	},
	productService: null,
	themeChangeListener: null,
	mounted() {
		this.productService.getProductsSmall().then(data => this.products = data);
		this.localUserInfo = JSON.parse(window.localStorage.getItem('userinfo'));

		this.themeChangeListener = (event) => {
			if (event.dark)
				this.applyDarkTheme();
			else
				this.applyLightTheme();
		};
		EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
			this.applyDarkTheme();
		}
		else {
			this.applyLightTheme();
		}

		this.getEventRecords();
		this.getHeaderStatistics();
		this.getChartStatistics();
		this.getNotifyList();

		var ms = dateUtil.quarterStartDate();
		var me = dateUtil.quarterEndDate();
		this.getRegionStatistics(ms.getTime(), me.getTime());

		//给个初值，避免初次调用时的Bug
		this.eventDetail.remind_img = this.noImgPic;

		// this.$on('data-a', name => {
		// 	this.name = name;//箭头函数内部不会产生新的this，这边如果不用=>,this指代Event
		// })
	},
	beforeUnmount() {
		EventBus.off('change-theme', this.themeChangeListener);
	},
	created() {
		this.productService = new ProductService();
	},
	methods: {
		errorMessage(msg) {
			this.$toast.add({ severity: 'error', summary: '发生错误', detail: msg, life: 3000 });
		},

		/**
		 * 类似于Sleep
		 *
		 * @param {string} millis 毫秒
		 * @return {} 无
		 */
		pausecomp(millis) {
			var date = new Date();
			var curDate = null;
			do { curDate = new Date(); }
			while (curDate - date < millis);
		},

		/**
		 * 24小时最新事件(获取当前系统时间戳，往前推24小时，得到时间范围)
		 * 本周事件统计，获取当前第几周，起始时间和结束时间
		 * 本月事件统计，获取当前第几月，起始时间和结束时间
		 *
		 * @param {number} start 起始时间，单位：毫秒
		 * @param {number} end 起始时间，单位：毫秒
		 * @return {Object} 统计数据 
		 */
		requestStatisticsInRange(start, end) {
			return this.$http('/api/admin/event/types', {
				enterprise_id: this.enterpriseId,
				time: start,
				times: end,
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					return res.data;
				} else {
					this.errorMessage(res.msg);
					return null;
				}
			})
		},

		calcStatistics(data) {
			var alarm_cnt = 0;

			try {
				for (var i = 0; i < 10; i++) {
					if (!isNaN(data.levels.alarm[i]))
						alarm_cnt += data.levels.alarm[i];
				}
			} catch (e) {
				console.log(e)
			}

			var reviewedCount = data.levels.checked; //已审核
			var notReviewedCount = data.levels.unchecked; //未审核
			var illegalCount = alarm_cnt; //违规
			var totalEventCount = reviewedCount + notReviewedCount;

			return { reviewedCount, notReviewedCount, illegalCount, totalEventCount }
		},

		async getHeaderStatistics() {
			var today = new Date();
			var ws = dateUtil.weekStartDate();
			var we = dateUtil.weekEndDate();
			var ms = dateUtil.monthStartDate();
			var me = dateUtil.monthEndDate();

			var d1 = await this.requestStatisticsInRange(today.getTime() - 86399999, today.getTime());
			var d2 = await this.requestStatisticsInRange(ws.getTime(), we.getTime() + 86399999);
			var d3 = await this.requestStatisticsInRange(ms.getTime(), me.getTime() + 86399999);

			// dayEventCount: 0,
			// weekEventCount: 0,
			// thisMonthReviewedCount: 0,
			// thisMonthNotReviewedCount: 0,

			this.dayEventCount = this.calcStatistics(d1).totalEventCount;
			this.weekEventCount = this.calcStatistics(d2).totalEventCount;
			var v = this.calcStatistics(d3);

			this.thisMonthNotReviewedCount = v.notReviewedCount;
			this.thisMonthReviewedCount = v.reviewedCount;

			this.$emit('updateEventMenu', this.dayEventCount);
		},

		//根据Daily数据，获取Pie图显示
		calcChartData(o_data) {
			let dailyObj = new Object();
			for (let i = 0; i < o_data.dates.length; i++) {
				//console.log(res.data.dates[i])f
				//console.log(res.data[res.data.dates[i]])
				let dateObj = o_data[o_data.dates[i]];

				for (let v in dateObj) {
					if (dailyObj[v] === undefined) {
						dailyObj[v] = new Array();
					}

					//dailyObj[v].push(dateObj[v].unsafe)
					dailyObj[v].push(dateObj[v].total)
				}
			}

			var names = [];
			var data = [];
			for (let v in dailyObj) {
				names.push(v)

				let sum = 0;
				for (let i = 0; i < dailyObj[v].length; i++) {
					sum = sum + dailyObj[v][i];
				}
				data.push(sum);
			}

			return { names, data };
		},

		requestEventDaily(start, end) {
			let enterprise_id = this.enterpriseId;

			return this.$http('/api/admin/event/daily', {
				enterprise_id: enterprise_id,
				status: 2,						//状态为2表示获取违规数据，为3表示总数
				time: start,
				times: end,
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					return res.data;
				}
				else {
					this.errorMessage(res.msg);
					return null;
				}
			})
		},

		async getChartStatistics() {
			//获取昨日、今日、本周、本月的数据
			let today = new Date(new Date().setHours(0, 0, 0, 0));
			let yesterday = dateUtil.yesterday();
			let ws = dateUtil.weekStartDate();
			let we = dateUtil.weekEndDate();
			let ms = dateUtil.monthStartDate();
			let me = dateUtil.monthEndDate();

			this.todayDailyStatData = await this.requestEventDaily(today.getTime(), today.getTime() + 86399999);
			this.yesterdayDailyStatData = await this.requestEventDaily(yesterday.getTime(), yesterday.getTime() + 86399999);
			this.thisWeekDailyStatData = await this.requestEventDaily(ws.getTime(), we.getTime() + 86399999);
			this.thisMonthDailyStatData = await this.requestEventDaily(ms.getTime(), me.getTime() + 86399999);

			let d1 = this.calcChartData(this.todayDailyStatData)
			let d2 = this.calcChartData(this.yesterdayDailyStatData)
			let d3 = this.calcChartData(this.thisWeekDailyStatData)
			let d4 = this.calcChartData(this.thisMonthDailyStatData)

			this.todayChartStatData.labels = d1.names;
			this.todayChartStatData.data = d1.data;
			this.yesterdayChartStatData.labels = d2.names;
			this.yesterdayChartStatData.data = d2.data;
			this.thisWeekChartStatData.labels = d3.names;
			this.thisWeekChartStatData.data = d3.data;
			this.thisMonthChartStatData.labels = d4.names;
			this.thisMonthChartStatData.data = d4.data;

			if (this.chartIndex == -1) {
				this.chartIndex = 1;
				this.onChartStatChange(1);
			}
		},

		onChartStatChange(index) {
			switch (index) {
				case 0:
					this.chartStatisticData.labels = this.yesterdayChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.yesterdayChartStatData.data;
					break;
				case 1:
					this.chartStatisticData.labels = this.todayChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.todayChartStatData.data;
					break;
				case 2:
					this.chartStatisticData.labels = this.thisWeekChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.thisWeekChartStatData.data;
					break;
				case 3:
					this.chartStatisticData.labels = this.thisMonthChartStatData.labels;
					this.chartStatisticData.datasets[0].data = this.thisMonthChartStatData.data;
					break;
			}

			this.chartIndex = index;
		},

		textColor(percent) {
			//'green-500','yellow-500','orange-500','red-500','red-900'
			if (percent < 20) {
				return 'green-500'
			}
			else if (percent < 40) {
				return 'yellow-500'
			}
			else if (percent < 60) {
				return 'orange-500'
			}
			else if (percent < 80) {
				return 'red-500'
			}
			else if (percent >= 80) {
				return 'red-900'
			}
		},

		calcRegionStat(data, level) {
			// index
			// title
			// subtitle
			// notReviewedPercent

			let index = 0;
			let arr = new Array();
			for (let n in data) {
				if (level == 2) {
					for (let n2 in data[n].children) {		//场地
						let total_cnt = 0;
						let not_reviewed_cnt = 0;
						let obj = new Object();

						obj.id = data[n].children[n2].id;
						obj.index = index;
						obj.title = n2;
						obj.subtitle = '企业：' + n;

						for (let n3 in data[n].children[n2].children) {		//区域
							for (let n4 in data[n].children[n2].children[n3].children) {		//事件类型
								not_reviewed_cnt = not_reviewed_cnt + data[n].children[n2].children[n3].children[n4].unread;
								total_cnt = total_cnt + data[n].children[n2].children[n3].children[n4].total;
							}
						}

						obj.totalCnt = total_cnt;
						obj.notReviewedCount = not_reviewed_cnt;
						obj.notReviewedPercent = Math.floor(not_reviewed_cnt * 100 / total_cnt);
						obj.textColor = this.textColor(obj.notReviewedPercent);

						index = index + 1;
						arr.push(obj);
					}
				}
				else if (level == 1) {
					let obj = new Object();
					let total_cnt = 0;
					let not_reviewed_cnt = 0;

					obj.id = data[n].id;
					obj.index = index;
					obj.title = n;
					obj.subtitle = '场地数量：' + Object.keys(data[n].children).length;

					for (let n2 in data[n].children) {		//场地
						for (let n3 in data[n].children[n2].children) {		//区域
							for (let n4 in data[n].children[n2].children[n3].children) {		//事件类型
								not_reviewed_cnt = not_reviewed_cnt + data[n].children[n2].children[n3].children[n4].unread;
								total_cnt = total_cnt + data[n].children[n2].children[n3].children[n4].total;
							}
						}
					}

					obj.totalCnt = total_cnt;
					obj.notReviewedCount = not_reviewed_cnt;
					obj.notReviewedPercent = Math.floor(not_reviewed_cnt * 100 / total_cnt);
					obj.textColor = this.textColor(obj.notReviewedPercent);
					index = index + 1;
					arr.push(obj);
				}
				else {
					for (let n2 in data[n].children) {		//场地
						for (let n3 in data[n].children[n2].children) {		//区域
							let total_cnt = 0;
							let not_reviewed_cnt = 0;
							let obj = new Object();

							obj.id = data[n].children[n2].children[n3].id;
							obj.index = index;
							obj.title = n3;
							obj.subtitle = '场地：' + n + '->' + n2;
							for (let n4 in data[n].children[n2].children[n3].children) {		//事件类型
								not_reviewed_cnt = not_reviewed_cnt + data[n].children[n2].children[n3].children[n4].unread;
								total_cnt = total_cnt + data[n].children[n2].children[n3].children[n4].total;
							}

							obj.totalCnt = total_cnt;
							obj.notReviewedCount = not_reviewed_cnt;
							obj.notReviewedPercent = Math.floor(not_reviewed_cnt * 100 / total_cnt);
							obj.textColor = this.textColor(obj.notReviewedPercent);

							index = index + 1;
							arr.push(obj);
						}
					}
				}
			}

			arr.sort((a, b) => {
				return b.notReviewedPercent - a.notReviewedPercent;
			});

			arr = arr.slice(0, 10);

			return arr;
		},

		requestRegionStatistics(start, end) {
			return this.$http('/api/admin/event/region', {
				enterprise_id: this.enterpriseId,
				time: start,
				times: end,
			}).then(res => {
				if (res.code == 200) {
					return res.data;
				} else {
					this.errorMessage(res.msg);
					return null;
				}
			})
		},

		async getRegionStatistics() {

			// var ms = dateUtil.monthStartDate();
			// var me = dateUtil.monthEndDate();
			var ms = dateUtil.quarterStartDate();
			var me = dateUtil.quarterEndDate();

			var d1 = await this.requestRegionStatistics(ms.getTime(), me.getTime());

			console.log(d1)

			this.enterpriseTopN = this.calcRegionStat(d1, 1);
			this.fieldTopN = this.calcRegionStat(d1, 2);
			this.regionTopN = this.calcRegionStat(d1, 3);

			this.topNList = this.regionTopN;
		},

		getEventRecords() {
			this.$http('/api/admin/event/index', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				page_size: 50,
				page: 1
			}).then(res => {
				if (res.code == 200) {
					this.eventRecordList = res.data.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getDetails(row) {
			console.log('here')
			this.$http('/api/admin/event/desc', {
				id: row.id
			}).then(res => {
				if (res.code == 200) {
					res.data.shr = res.data.users_info.length == 0 ? '' : res.data.users_info[0].user_name
					this.eventDetail = res.data

					if (this.eventDetail.level == 1) {
						this.eventDetail.level = 'A'
					} else if (this.eventDetail.level == 2) {
						this.eventDetail.level = 'B'
					} else if (this.eventDetail.level == 3) {
						this.eventDetail.level = 'C'
					} else if (this.eventDetail.level == 4) {
						this.eventDetail.level = 'D'
					}
				} else {
					//this.$Message.error(res.msg)
					this.$toast.add({ severity: 'error', summary: '请求错误', detail: 'Name: ' + event.data.name, life: 3000 });
				}
			})
			this.displayDetail = true;
		},

		getNotifyList() {

			let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
			let yesterday = new Date(dateUtil.yesterday().setHours(0, 0, 0)).getTime();

			this.notifyList.length = 0;
			this.$http('/api/admin/message/index', {
				page_size: 10
			}).then(res => {
				//console.log(res)

				let arr_today = new Object();
				let arr_yesterday = new Object();
				let arr_early = new Object();

				arr_today.title = '今天 ' + dateUtil.formatDate(new Date(new Date().setHours(0, 0, 0)));
				arr_today.list = new Array();
				arr_yesterday.title = '昨天' + dateUtil.formatDate(dateUtil.yesterday());
				arr_yesterday.list = new Array();
				arr_early.title = '更早';
				arr_early.list = new Array();

				for (let i = 0; i < res.data.data.length; i++) {
					if (res.data.data[i].times >= today) {
						arr_today.list.push(res.data.data[i])
					}
					else if (res.data.data[i].times < today && res.data.data[i].times > yesterday) {
						arr_yesterday.list.push(res.data.data[i])
					}
					else {
						arr_early.list.push(res.data.data[i])
					}
				}

				if (arr_today.list.length > 0)
					this.notifyList.push(arr_today)

				if (arr_yesterday.list.length > 0)
					this.notifyList.push(arr_yesterday)

				if (arr_early.list.length > 0)
					this.notifyList.push(arr_early)

				console.log(this.notifyList)
			})
		},

		gotoEventRecords() {
			this.$router.push({
				name: 'eventrecords',
			});
		},
		gotoNotifyList() {
			this.$router.push({
				name: 'notifylist',
			});
		},
		onDetailHide() {
			this.eventDetail.remind_img = this.noImgPic;
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		isDarkTheme() {
			return this.$appState.darkTheme === true;
		},
		onError(event) {
			console.log(event)
		},
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color: 'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color: 'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
